<template functional>
  <div class="contacts" :class="data.staticClass">
    <a
      class="contacts__item"
      v-for="(item, key) in props.items"
      :key="key"
      :href="item.link"
    >
      <svg-icon class="contacts__icon" :icon="item.icon" />
      <span class="contacts__name" v-text="item.name" />
    </a>
  </div>
</template>

<script>

export default {
  name: 'app-contacts',
};
</script>

<style lang="scss">
.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include MediaQuery($M) {
    flex-direction: row;

  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 1rem;
    color: $white;
    text-decoration: none;

    @include MediaQuery($M) {
      margin: 0 5rem 0 0;
    }
  }

  &__icon {
    @include square(2.8rem);
    margin: 0 1rem 0 0;
    fill: $white;

    @include MediaQuery($S, $M) {
      @include square(2.6rem)
    }
  }
}

</style>
