<template functional>
  <img class="photo" :src="props.image" :alt="props.alt">
</template>

<script>
export default {
  name: 'app-photo',
}
</script>

<style lang="scss">
.photo {
  @include square(26rem);
  display: block;
  margin: 0 auto 3rem;
  border-radius: 50%;
  object-fit: cover;

  @include MediaQuery($L) {
    margin: auto;
  }
}
</style>
