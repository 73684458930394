<template functional>
  <div class="social" :class="data.staticClass">
    <div
      class="social__item"
      v-for="(item, key) in props.items"
      :key="key"
    >
      <a class="social__link" :href="item.link" target="_blank">
        <svg-icon class="social__icon" :icon="item.icon" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-social',
}
</script>

<style lang="scss">
.social {
  display: flex;
  margin: 5rem 0 0;
  gap: 1rem;

  &--widget {
    margin: 1rem 0 0;
  }

  @include MediaQuery(null, $S) {
    margin: 2rem 0 0;
  }

  &__link {
    @include square(3.2rem);
    display: block;
    border-radius: 50%;
    transition: opacity .3s ease;

    &:hover {
      opacity: .8;
    }
  }

  &__icon {
    @include square(100%);
    fill: $white;
  }
}
</style>
