<template>
  <div id="app">
    <app-preloader :load="!loaded" />

    <app-layout v-if="loaded">

      <template #image>
        <app-photo :image="cv.photo" :alt="fullName" />
      </template>

      <template #card>
        <app-card v-bind="{ ...cv }" />
      </template>

      <template #aside="{ className }">
        <app-widget title="About me">
          <app-widget-content :text="cv.about_me" />
        </app-widget>

        <app-widget-group-list :list="cv.skills">
          <template #item="{ title, list }">
            <app-widget :title="title">
               <app-widget-list :items="list" />
            </app-widget>
          </template>
        </app-widget-group-list>

        <app-widget title="Contact me">
          <app-widget-reference :name="fullName" :position="cv.position" :contacts="cv.contacts" />
          <app-card-social class="social--widget" :items="cv.social" />
        </app-widget>
      </template>

      <template #content>
        <app-content-block title="Education">
          <app-content-block-list :items="cv.education" />
        </app-content-block>

        <app-content-block title="Work Experience">
          <app-content-block-list :items="cv.experience" />
        </app-content-block>
      </template>

    </app-layout>
  </div>
</template>

<script>
import AppWidgetGroupList from '@/components/components/app-widget/app-widget-group-list.vue';
import api from './api';

export default {
  name: 'App',
  components: { AppWidgetGroupList },

  data () {
    return {
      cv: {},
    }
  },

  computed: {
    loaded () {
      return !!Object.keys(this.cv).length;
    },

    fullName () {
      const { name: { first, last } } = this.cv;

      return [first, last].join(' ');
    },
  },

    methods: {
        async setDate () {
            try {
                this.cv = await api.response('cv/qa/');
            } catch(e) {
               console.error(e);
            }
        },
    },

  created () {
    this.setDate();
  },
}
</script>
