<template functional>
  <div class="list">
    <div
      class="list__item"
      v-for="(item, i) in props.items"
      :key="`item-${i}`"
    >
      <div class="list__label" v-text="item.name" />
      <div class="list__item-level">
        <span
          v-for="n in 5"
          :key="`level-${n}`"
          :class="[n <= item.level ? 'active' : '']"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-widget-list',
}
</script>

<style lang="scss">

.list {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem;
    padding: 0 0 2rem;
    border-bottom: .1rem solid white(.02);
    color: $white;
    font-size: 1.6rem;
    line-height: 1.4;

    &:last-child {
      margin: 0;
      padding: 0;
      border-bottom: none;
    }

    @include MediaQuery($S) {
      font-size: 1.8rem;
    }
  }

  &__item-level {
    display: flex;

    span {
      display: block;
      margin: 0 0 0 .7rem;
      background: white(.05);
      @include square(1.2rem);

      @include MediaQuery($S) {
        @include square(1.2rem);
        margin: 0 0 0 1rem;
      }

      &.active {
        background: $color-second;
      }
    }
  }
}
</style>
