<template>
  <div class="block-list">
    <div
      class="block-list__item"
      v-for="(item, i) in itemsList"
      :key="`exp-${i}`"
    >
      <div class="block-list__date" v-text="item.date" />
      <div class="block-list__title" v-text="item.title" />
      <div class="block-list__city" v-text="item.location" />
      <div class="block-list__content" v-html="item.description" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-content-block-list',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },

  computed: {
    itemsList () {
      return this.items.map((item) => ({
        ...item,
        date: [item.date.start, item.date.end].filter(i => i.length).join(' - '),
      }));
    },
  },
}
</script>

<style lang="scss">

.block-list {
  &__item {
    padding: 0 0 3rem 3rem;
    position: relative;

    &::before {
      @include absolute(0, null, null, .7rem);
      width: .2rem;
      height: 100%;
      background: $color-second;
      content: '';
    }

    &::after {
      @include absolute(0, null, null, 0);
      @include square(1.2rem);
      background: $white;
      border: .2rem solid $color-second;
      border-radius: 50%;
      content: '';
    }

    &:last-child {
      &::before {
        display: none;
      }
    }
  }

  &__date {
    display: block;
    margin: 0 0 1rem;
    padding: .2rem 0 0;
    font-size: 1.4rem;
  }

  &__title {
    margin: 0 0 .6rem;
    color: $gray-900;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.4;
    text-transform: uppercase;
  }

  &__city {
    margin: 0 0 1rem;
    font-size: 1.4rem;
  }

  &__content {
    color: $gray-700;
    font-size: 1.4rem;
    line-height: 1.6;

    ul {
      li {
        margin: 0 0 .8rem;
        padding: 0 0 0 2rem;
        position: relative;
        list-style-type: none;
        line-height: 1.6;

        &::before {
          @include square(.4rem);
          display: block;
          position: absolute;
          top: .8rem;
          left: .3rem;
          background: transparent;
          border: .2rem solid $color-second;
          border-radius: 50%;
          content: '';
        }
      }
    }
  }
}
</style>
