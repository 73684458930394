<template functional>
  <div class="block">
    <div class="block__title" v-text="props.title" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'add-content-block',
}
</script>

<style lang="scss">
.block {
  margin: 0 0 20px;

  &__title {
    margin: 0 0 4rem;
    padding: 0 0 3rem;
    position: relative;
    color: $color-second;
    font-size: 2.6rem;
    font-family: $font-second;
    text-transform: uppercase;

    &::before,
    &::after {
      @include absolute(null, null, 0, 0);
      display: block;
      height: 2px;
      background: $color-second;
      content: '';
    }

    &::before {
      width: 100%;
      opacity: .2;
    }

    &::after {
      width: 50px
    }
  }
}
</style>
