<template functional>
  <div class="layout">
    <header class="layout__header">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
            <slot name="image" />
          </div>
          <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
            <slot name="card" />
          </div>
        </div>
      </div>
    </header>
    <main class="layout__main">
      <section class="layout__section">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
              <div class="layout__aside">
                <slot name="aside" />
              </div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
              <div class="layout__content">
                <slot name="content" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'app-layout',
}
</script>

<style lang="scss">
.layout {
  &__header {
    padding: 3rem 0;
    background: $color-primary;

    @include MediaQuery($M) {
      padding: 7rem 0;
    }
  }

  &__aside {
    width: 100%;
    height: 100%;
    padding: 3rem;
    position: relative;
    background: $color-three;

    @include MediaQuery($M) {
      padding: 5rem;
    }

    &::before {
      @include absolute(0, 100%);
      @include size(1rem, 100%);
      display: block;
      width: 50vw;
      position: absolute;
      right: 99%;
      background: $color-three;
      content: '';

    }

    @include MediaQuery($XS, $S) {
      &::after {
        @include absolute(0, null, null, 100%);
        @include size(calc(50vw - 46rem / 2), 100%);
        display: block;
        background: $color-three;
        content: '';
      }
    }

    @include MediaQuery(null, $XS) {
      &::after {
        @include absolute(0, null, null, 100%);
        @include size(1rem, 100%);
        display: block;
        background: $color-three;
        content: '';
      }
    }
  }

  &__section {
    overflow: hidden;
  }


  &__content {
    padding: 5rem 0 0 3rem;

    @include MediaQuery(null, $M) {
      padding: 3rem;
    }
  }
}

</style>
