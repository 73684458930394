<template>
  <div class="app-widget-group-list">
    <slot v-for="item in list" name="item" v-bind="item" />
  </div>
</template>

<script>
export default {
  name: 'app-widget-group-list',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
