<template functional>
  <div class="reference">
    <div
      class="reference__item reference__item--name"
      v-text="props.name"
    />
    <div
      class="reference__item reference__item--position"
      v-text="props.position"
    />
    <a
      class="reference__item reference__item--phone"
      :class="`reference__item--${item.icon}`"
      v-for="(item, key) in props.contacts"
      :key="key"
      :href="item.link"
      v-text="item.name"
    />
  </div>
</template>

<script>
export default {
  name: 'app-widget-reference',
}
</script>

<style lang="scss">

.reference {
  display: flex;
  flex-direction: column;
  color: $white;

  &__item {
    margin: 0 0 1.2rem;
    color: $white;
    text-decoration: none;

    &--name {
      font-size: 2.2rem;
      font-family: $font-second;
    }

    &--position {
      color: $color-second;
      font-weight: 400;
    }
  }
}
</style>
