<template functional>
  <div class="card">
    <div class="card__name">
      <span class="card__name-part" v-text="`${props.name.first} `" />
      <span class="card__name-part card__name-part--selected" v-text="props.name.last" />
    </div>
    <div class="card__position" v-text="props.position" />
    <app-card-contact class="card__contacts" :items="props.contacts" />
    <app-card-social class="card__social" :items="props.social" />
  </div>
</template>

<script>

export default {
  name: 'app-card',
}
</script>

<style lang="scss">
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include square(100%);

  @include MediaQuery($S) {
    align-items: flex-start;
  }

  &__name {
    margin: 0 0 20px;
    color: $white;
    font-size: 2.8rem;
    font-family: $font-second;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;

    @include MediaQuery($S, $M) {
      font-size: 3.2rem;
    }

    @include MediaQuery($M) {
      font-size: 6.2rem;
      text-align: left;
    }
  }

  &__name-part {

    &--selected {
      margin: 0;
      color: $color-second;
    }
  }

  &__position {
    margin: 0 0 5rem;
    color: $white;
    font-size: 1.8rem;

    @include MediaQuery(null, $S) {
      margin: 0 0 2rem;
    }

    &::after {
      display: block;
      width: 50%;
      height: .2rem;
      margin: 1.5rem auto 0;
      background: $color-second;
      content: '';

      @include MediaQuery($S) {
        margin: 1.5rem 0 0;
      }
    }

    @include MediaQuery($S, $M) {
      margin: 0 0 3rem;
      font-size: 1.8rem;
    }
  }
}
</style>
