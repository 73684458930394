<template functional>
    <div class="widget" :class="data.staticClass">
        <div class="widget__title" v-text="props.title" />
        <div class="widget__inner">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'app-widget',
}
</script>

<style lang="scss">

.widget {
    margin: 0 0 4rem;
    position: relative;
    z-index: 2;

    &__title {
        padding: 0 0 2rem;
        margin: 0 0 2rem;
        position: relative;
        font-family: $font-second;
        font-size: 2.2rem;
        line-height: 1.4;
        color: $white;
        text-transform: uppercase;

        @include MediaQuery($S) {
            font-size: 2.6rem;
        }

        &::before,
        &::after {
            height: 2px;
            bottom: 0;
            left: 0;
            background: $color-second;
            @include pseudo;
        }

        &::before {
            width: 100%;
            opacity: .1;
        }

        &::after {
            width: 50px;
        }
    }
}
</style>
