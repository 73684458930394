import axios from 'axios';

const client = axios.create({
    baseURL: 'https://4dev.website/wp-json/v1/',
});

client.interceptors.response.use(
    (response) => response.data,
    (e) => Promise.reject(e),
);

export default {
    response (endpoint, _limit) {
        return client.get(endpoint, {
            params: {
                _limit,
            },
        });
    },
};
