<template functional>
  <div class="widget-content" v-html="props.text" />
</template>

<script>
export default {
  name: 'app-widget-content',
}
</script>

<style lang="scss">
.widget-content {
  color: $gray-300;
  line-height: 1.6;
}
</style>
