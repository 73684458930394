<template>
  <svg class="svg-icon">
    <use :xlink:href="iconPath"></use>
  </svg>
</template>

<script>

export default {
  name: 'svg-icon',

  props: {
    icon: {
      type: String,
      default: '',
    },
  },

  computed: {
    iconPath () {
      let icon = require(`@/assets/icons/${this.icon}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }
      return icon.url;
    },
  },
};
</script>
