<template>
  <transition name="fade">
    <div class="preloader" v-if="load">
      <img class="preloader__icon" src="@/assets/img/preloader.svg" alt="loading...">
    </div>
  </transition>
</template>

<script>
export default {
  name: 'preloader',

  props: {
    load: Boolean,
    default: true,
  },
}
</script>

<style lang="scss">
.preloader {
  @include fixed(0, 0, 0, 0, 10);
  @include square(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-three;

  &__icon {
    @include square(8rem)
  }
}
</style>
